// import React from "react";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
// import styled from "@emotion/styled";
import React, { useState, useEffect, ChangeEvent } from "react";
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  IconButton,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { ExpandMore, ExpandLess } from "@mui/icons-material";
import { fontTheme, insertLineBreaks } from "../common/utils";

const modal = css`
  margin: 64px auto;
  @media screen and (min-width: 1194px) {
    width: 75%;
  }
  @media screen and (min-width: 1366px) {
    width: 70%;
  }
`;
const card = css`
  padding: 8px;
  display: flex;
  flex-direction: row;
`;
const table = css`
  border-collapse: collapse;
  width: 350px;
`;
const secondTable = css`
  position: sticky;
  left: 350px;
  border-collapse: collapse;
  width: 400px;
`;
const thirdTable = css`
  position: sticky;
  left: 750px;
  border-collapse: collapse;
  width: 400px;
`;
const tableContainer = css`
  width: 350px;
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
`;
const secondTableContainer = css`
  width: 400px;
  max-height: 400px;
  overflow-x: hidden;
`;
const thirdTableContainer = css`
  width: 150px;
  max-height: 400px;
  overflow-x: hidden;
`;
const headerCell = css`
  width: 350px;
  height: 40px;
  border: none;
  padding: 8px;
  background-color: #e0ffee;
  align-items: center;
  z-index: 10;
`;
const secondHeaderCell = css`
  width: 400px;
  height: 40px;
  border: none;
  padding: 8px;
  background-color: #e0ffee;
  align-items: center;
`;
const thirdHeaderCell = css`
  width: 150px;
  height: 40px;
  border: 1px solid #ccc;
  border: none;
  padding-left: 0;
  // background-color: #e0ffee;
  align-items: center;
`;
const accordion = css`
  cursor: pointer;
  padding: 8px;
  border: none;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
`;
const rowBorder = css`
  width: 350px;
  height: 70px;
  border: none;
`;
const secondRowBorder = css`
  width: 370px;
  height: 70px;
  border: none;
`;
const thirdRowBorder = css`
// display: flex;
  position: sticky;
  top: 0px;
  border: none;
`;
const rowFlex = css`
  align-items: center;
  display: flex;
`;
const infoCheckBox = css`
  align-items: center;
  display: flex;
  flex-direction: column;
`;
const infoCheckBoxItem = css`
  display: flex;
  align-items: center;
  margin-bottom: 4px;
`;

const infoCheckBoxText = css`
  margin-left: 8px;
  width: 150px;
  white-space: pre-wrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const designButton = css`
  border: 2px solid #00CC5B;
  background-color: white;
  color: black;
  border-radius: 20px;
  padding: 5px;
  margin-left: 10px;
  cursor: pointer;
  &:hover {
    background-color: #f0f0f0;
  }
`;
const formControlLabelStyle = (isSelected: any) => css`
  margin: 0;
  border-radius: 4px;
  padding: 8px;
  border: none;
`;
const radioStyle = (isSelected: any) => ({
  color: isSelected ? '#00CC5B' : '#ccc',
  '&.Mui-checked': {
    color: '#00CC5B',
  },
});

type PMASortModalProps = {
  onConfirm: (selected: string[], tobaccoFlag: boolean, reservationFlag: boolean, specialFlag: boolean, value: string) => void;
  storePmaAllList: any[];
  storePmaData: any[];
  selectedItems: any[];
  tobaccoFlag: boolean;
  reservationFlag: boolean;
  specialFlag: boolean;
  value: string;
  setTobaccoFlag: (flag: boolean) => void;
  setReservationFlag: (flag: boolean) => void;
  setSpecialFlag: (flag: boolean) => void;
  setValue: (value: string) => void;

};

const PMASortModal: React.FC<PMASortModalProps> = ({ 
  onConfirm, storePmaAllList, storePmaData, selectedItems, tobaccoFlag, reservationFlag, specialFlag, 
  setTobaccoFlag, setReservationFlag, setSpecialFlag, value, setValue
}) => {  
  const [checkedInfo, setCheckedInfo] = useState<{ [key: string]: boolean }>({});
  const [openAccordion, setOpenAccordion] = useState<string | null>(null);
  const [checkedPMA, setCheckedPMA] = useState<{ [key: string]: boolean }>({});

  useEffect(() => {
    const initialCheckedInfo: { [key: string]: boolean } = {};
    if (storePmaData && storePmaAllList) {
      storePmaAllList.forEach((data: any) => {
        const key = `${data.pma_cd}_${data.info_cd}`;
        // selectedItems に key が含まれている場合は true、そうでない場合は false を設定
        initialCheckedInfo[key] = selectedItems.includes(key);
        const pmaKey = data.pma_cd;
        initialCheckedInfo[pmaKey] = selectedItems.some(item => item.startsWith(`${pmaKey}_`));
      });
      setCheckedInfo(initialCheckedInfo);
    }
  }, [storePmaData, storePmaAllList, selectedItems]);

  useEffect(() => {
    // tobaccoFlag の変更時に "61" のチェックボックスの状態を更新
    setCheckedInfo(prevState => ({
      ...prevState,
      "61": !tobaccoFlag, // tobaccoFlag が true なら false、false なら true
      "61_undefined": !tobaccoFlag
    }));
  }, [tobaccoFlag]);

  const handleConfirm = () => {
    const selectedPmaCd = Object.keys(checkedInfo).filter(key => checkedInfo[key]);
    onConfirm(selectedPmaCd, tobaccoFlag, reservationFlag, specialFlag, value); // 更新された値を渡す
};

  // 全部のpmaにチェックを入れる
  const handleSelectAll = () => {
    const newCheckedInfo: { [key: string]: boolean } = {};
    storePmaAllList.forEach((data: any) => {
      newCheckedInfo[`${data.pma_cd}_${data.info_cd}`] = true;
      newCheckedInfo[data.pma_cd] = true;
    });
    setCheckedInfo(newCheckedInfo);
  };

  // クリアボタンを押した際にチェック全部外す
  const handleClear = () => {
    const initialCheckedInfo: { [key: string]: boolean } = {};
    storePmaAllList.forEach((data: any) => {
      initialCheckedInfo[`${data.pma_cd}_${data.info_cd}`] = false;
      initialCheckedInfo[data.pma_cd] = false;
    });
    setCheckedInfo(initialCheckedInfo);
  };

  const handleExcludeTobacco = (event: ChangeEvent<HTMLInputElement>) => {
    setTobaccoFlag(event.target.checked);
  };
  const handleExcludeReservation = (event: ChangeEvent<HTMLInputElement>) => {
    setReservationFlag(event.target.checked);
  };
  const handleExcludeSpecial = (event: ChangeEvent<HTMLInputElement>) => {
    setSpecialFlag(event.target.checked);
  };

  // pma_cd、info_cdが重複しないmodalに表示するオブジェクトの作成
  const groupedData = storePmaAllList.reduce((acc: any, item: any) => {
    if (!acc[item.pma_cd]) {
      acc[item.pma_cd] = {
        pma_cd: item.pma_cd,
        pma_name: item.pma_name,
        infos: []
      };
    }
    const infoExists = acc[item.pma_cd].infos.some((info: any) => info.info_cd === item.info_cd );
    if (!infoExists) {
      acc[item.pma_cd].infos.push({
        info_cd: item.info_cd,
        info_name: item.info_name,
      });
    }
    return acc;
  }, {});

  // ソート処理を追加
  const sortedGroupedData = Object.values(groupedData).sort((a: any, b: any) => {
    return a.pma_cd.localeCompare(b.pma_cd); // PMA (pma_cd) でソート
  });

  sortedGroupedData.forEach((group: any) => {
    group.infos.sort((a: any, b: any) => {
      return a.info_name.localeCompare(b.info_name); // 情報分類 (info_name) でソート
    });
  });

  // アコーディオン制御
  const handleAccordionClick = (pma_cd: string) => {
    setOpenAccordion(openAccordion === pma_cd ? null : pma_cd);
  };

  // pmaのチェックボックスが選択された場合、配下のinfoのチェックボックスを制御
  const handlePmaCheckboxChange = (pma_cd: string) => {
    const isChecked = !checkedInfo[pma_cd];
    setCheckedInfo((prevState: any) => {
      const newState = { ...prevState, [pma_cd]: isChecked };
      storePmaAllList.filter((data: any) => data.pma_cd === pma_cd).forEach((data: any) => {
        newState[`${pma_cd}_${data.info_cd}`] = isChecked;
      });
      return newState;
    });
  };

  const handleInfoCheckboxChange = (pma_cd: string, info_cd: string) => {
    setCheckedInfo((prevState: any) => {
      const newState = { ...prevState, [`${pma_cd}_${info_cd}`]: !prevState[`${pma_cd}_${info_cd}`] };
      const relatedInfoItems = storePmaAllList.filter((d: any) => d.pma_cd === pma_cd);
      const isAnyInfoChecked = relatedInfoItems.some((d: any) => newState[`${pma_cd}_${d.info_cd}`]);
      newState[pma_cd] = isAnyInfoChecked;
      return newState;
    });
  };
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
    setCheckedPMA({ ...checkedPMA, [event.target.name]: event.target.checked });
    //  "61"のチェック状態をtobaccoFlagに反映する
    if (event.target.name === "61") {
      setTobaccoFlag(event.target.checked);
    }
  };

  return (
    <>
        <ThemeProvider theme={fontTheme()}>
          <Card css={modal}>
            <CardContent css={card}>
              <TableContainer css={tableContainer}>
                <Table stickyHeader css={table}>
                  <TableHead style={{height: "75px" }}>
                    <TableRow css={rowBorder}>
                      <TableCell css={headerCell}>
                        PMA・情報分類
                      </TableCell>
                    </TableRow>
                    <TableRow css={rowBorder}>
                      <TableCell>
                        <Button onClick={handleSelectAll} css={designButton}>
                          すべて
                        </Button>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow css={rowBorder}>
                      <TableCell rowSpan={2}>
                        {sortedGroupedData.map((group: any) => (
                          <div key={group.pma_cd}>
                            <div
                              css={accordion}
                              onClick={() => handleAccordionClick(group.pma_cd)}
                            >
                              <IconButton onClick={() => handleAccordionClick(group.pma_cd)}>
                                {openAccordion === group.pma_cd ? <ExpandLess /> : <ExpandMore />}
                              </IconButton>
                              <Checkbox
                                checked={!!checkedInfo[group.pma_cd]}
                                onChange={() => handlePmaCheckboxChange(group.pma_cd)}
                                onClick={(e) => e.stopPropagation()}
                                style={{ color: "#00cc5b" }}
                              />
                              {group.pma_name}
                            </div>
                            {openAccordion === group.pma_cd && (
                              <div css={infoCheckBox}>
                                {group.infos.map((info: any) => (
                                  <div key={info.info_cd} css={infoCheckBoxItem}>
                                    <Checkbox
                                      checked={!!checkedInfo[`${group.pma_cd}_${info.info_cd}`]}
                                      onChange={() => handleInfoCheckboxChange(group.pma_cd, info.info_cd)}
                                      style={{ color: "#00cc5b" }}
                                    />
                                    <span css={infoCheckBoxText}>{insertLineBreaks(info.info_name, 7)}</span>
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                        ))}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <TableContainer css={secondTableContainer}>
                <Table stickyHeader css={secondTable}>
                  <TableHead style={{ width: "400px"}}>
                    <TableRow css={secondRowBorder}>
                      <TableCell css={secondHeaderCell}>
                        デイリー/非デイリー
                      </TableCell>
                    </TableRow>
                    <TableRow css={secondRowBorder}>
                      <TableCell style={{ padding: "8px"}}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={tobaccoFlag}
                              onChange={handleExcludeTobacco}
                              name="tobaccoFlag"
                              style={{
                                color: "#00cc5b",
                              }}
                            />
                          }
                          label="煙草除く"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={reservationFlag}
                              onChange={handleExcludeReservation}
                              name="reservationFlag"
                              style={{
                                color: "#00cc5b",
                              }}
                            />
                          }
                          label="予約除く"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={specialFlag}
                              onChange={handleExcludeSpecial}
                              name="specialFlag"
                              style={{
                                color: "#00cc5b",
                              }}
                            />
                          }
                          label="特発除く"
                        />
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow css={secondRowBorder}>
                      <RadioGroup
                        defaultValue="all"
                        value={value}
                        onChange={handleChange}
                        row
                      >
                        <FormControlLabel
                          value="all"
                          control={
                            <Radio 
                              sx={radioStyle(value === 'all')}
                            />
                          }
                          label="すべて"
                          css={formControlLabelStyle(value === 'all')}
                        />
                        <FormControlLabel
                          value="daily"
                          control={
                            <Radio 
                              sx={radioStyle(value === 'daily')}
                            />
                          }
                          label="デイリー"
                          css={formControlLabelStyle(value === 'daily')}
                        />
                        <FormControlLabel
                          value="nodaily"
                          control={
                            <Radio 
                              sx={radioStyle(value === 'nodaily')} 
                            />
                          }
                          label="非デイリー"
                          css={formControlLabelStyle(value === 'nodaily')}
                        />
                      </RadioGroup>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <TableContainer css={thirdTableContainer}>
                <Table css={thirdTable} >
                  <TableCell css={[rowFlex, thirdRowBorder, thirdHeaderCell]}>
                    <Button onClick={handleConfirm} css={designButton}>
                      決定
                    </Button>
                    <Button onClick={handleClear} css={designButton}>
                      クリア
                    </Button>
                  </TableCell>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        </ThemeProvider>
    </>
  );
};
export default PMASortModal;

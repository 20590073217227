
import React, { useState, useEffect, useRef } from "react";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import styled from '@emotion/styled';
import { useLocation, useNavigate } from "react-router-dom";
import {
  Card,
  CardContent,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Modal,
  Paper,
  CircularProgress,
} from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { ThemeProvider } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Header from "../common/Header";
import TimeOutDisplay from "../common/TimeOutDisplay";
import { getWeekOptions, getMonthOptions, timer, fontTheme, insertLineBreaks } from "../common/utils";
import APIErrorModal from "../common/APIErrorModal";
import PMASortModal from "../common/PMASortModal";
import ToggleButtonComponent from "../common/ToggleButtonComponent";
import { userAuthCheck } from "../auth/authUser";
import { getUserInfo } from "../../data/userInfo";
import { apiRequest } from "../../apis/util";

const card = css`
  margin: 80px 16px 8px 16px;
  width: 100%;
  height: 100%;
  @media screen and (min-width: 1194px) {
    height: 620px;
  }
  @media screen and (min-width: 1366px) {
    height: 550px;
  }
`;
const table = css`
  table-layout: fixed;
  width: 100%;
  border: 0;
`;
const cardContent = css`
  padding: 8px;
  height: 100%;
  display: flex;
  flex-direction: column;
  border: 0;
`;
const tableContainer = css`
  border: 0;
  width: 99%;
  flex-grow: 1;
  overflow: auto;
  margin: 8px 8px 16px 8px;
  border: 0;
  @media screen and (min-width: 1194px) {
    max-height: 620px;
  }
  @media screen and (min-width: 1280px) {
    max-height: 460px;
  }
  @media screen and (min-width: 1366px) {
    max-height: 550px;
  }
`;
const commonCell = css`
  margin: 0;
  padding: 4px;
  white-space: pre-wrap;
  border-collapse: collapse;
  border-spacing: 0;
  border: 1px solid #c4c4c4;
  font-size: 12px;
  box-sizing: border-box;
`;
const verticalHeaderCell = css`
  ${commonCell}
  width: 50px;
  height: 90px;
  background-color: #e0ffee;
  text-align: center;
  writing-mode: vertical-rl;
  text-orientation: upright;
  border-top: 2px solid #c4c4c4;
  border-bottom: 2px solid #c4c4c4;
  border-right: 2px solid #c4c4c4;
  border-left: 0;
`;
const itemNameHeaderCell = css`
  ${verticalHeaderCell}
  width: 150px;
  z-index: 100;
`;
const infoItemNameHeaderCell = css`
  ${verticalHeaderCell}
  width: 110px;
  z-index: 50;
`;
const minVerticalHeaderCell = css`
  ${verticalHeaderCell}
  width: 40px;
`;
const besideHeaderCell = css`
  ${commonCell}
  padding: 0;
  width: 360px;
  height: 25px;
  background-color: #e0ffee;
  text-align: center;
  white-space: nowrap;
  text-align: center;
  box-sizing: border-box;
  border-top: 2px solid #c4c4c4;
  border-bottom: 2px solid #c4c4c4;
  border-right: 2px solid #c4c4c4;
  border-left: 0;
`;
const minBesideHeaderCell = css`
  ${besideHeaderCell}
  width: 70px;
`;
const joinHeaderCell = css`
  ${commonCell}
  background-color: #e0ffee;
  text-align: center;
  white-space: nowrap;
  text-align: center;
  box-sizing: border-box;
  width: 105px;
  height: 30px;
  border-top: 2px solid #c4c4c4;
  border-bottom: 2px solid #c4c4c4;
  border-right: 2px solid #c4c4c4;
  border-left: 0;
  z-index: 100;
`;
const colHeaderCell = css`
  ${commonCell}
  position: sticky;
  top: 36px;
  width: 30px;
  height: 90px;
  background-color: #e0ffee;
  writing-mode: vertical-rl;
  text-orientation: upright;
  border-top: 0;
  border-bottom: 2px solid #c4c4c4;
  border-right: 2px solid #c4c4c4;
  border-left: 0;
`;
const secondHeader = css`
  ${commonCell}
  padding: 4px;
  position: sticky;
  width: 60px;
  background-color: #e0ffee;
  text-align: center;
  white-space: nowrap;
  text-align: center;
  box-sizing: border-box;
  top: 36px;
  border-top: 0;
  border-bottom: 2px solid #c4c4c4;
  border-right: 2px solid #c4c4c4;
  border-left: 0;
`;
const numberCell = css`
  ${commonCell}
  position: sticky;
  padding: 2px;
  height: 45px;
  font-size: 12px;
  text-align: center;
  border-top: 0;
  border-bottom: 2px solid #c4c4c4;
  border-right: 2px solid #c4c4c4;
  border-left: 0;
  background-color: #ffffff;
`;
const nameCell = css`
  ${numberCell}
  padding: 4px;
  text-align: left;
`;
const circularProgress = css`
  position: absolute;
  top: 45%;
  left: 45%;
  opacity: 0.8;
  color: #ccc;
`;
const selectTerm = css`
  width: 200px;
  height: 35px;
  margin: 8px;
  padding: 0;
  text-align: left;
  background-color: #fff;
`;
const buttonStyle = css`
  background-color: #fff;
  color: #000;
  border: 1px solid #ccc;
  padding: 8px;
  margin: 4px;
  white-space: pre-wrap;
  border-radius: 8px;
`;
const containerStyle = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
`;
const rightContainerStyle = css`
  margin-left: auto;
`;
const roundIcon = css`
  display: flex;
  align-items: center;
  left: 2px;
  height: 25px;
  width: 25px;
  &:hover {
    border-radius: 50%;
  }
`;
const sortButton = css`
  position: absolute;
  padding: 0;
  left: 2px;
  align-items: center;
  bottom: 5px;
`;
type Order = "asc" | "desc";

const AssortmentAnalysis: React.FC<{}> = ({}) => {
  const navigate = useNavigate();
  const [data, setData] = useState<any>({});
  const [sortedFilterData, setSortedFilterData] = useState<string[]>([]);
  const [storePmaData, setStorePmaData] = useState<any[]>([]);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [filterData, setFilterData] = useState<any[]>([]);
  const [tenpoCode, setTenpoCode] = useState<string | null>("");
  const [tenpoName, setTenpoName] = useState<string>("");
  const [date, setDate] = useState("");
  const [order, setOrder] = useState<Order>("asc");
  const [item, setItem] = useState("AllItems");
  const [orderBy, setOrderBy] = useState<string>(
    "sales_quantity_rank"
  );
  const [flagWM, setFlagWM] = useState("AssortmentAnalysisWeekly");
  const [weekDateOptions, setWeekDateOptions] = useState<string[]>([]);
  const [monthDateOptions, setMonthDateOptions] = useState<string[]>([]);
  const [weekDateValue, setWeekDateValue] = useState("");
  const [monthDateValue, setMonthDateValue] = useState("");
  const [apiData, setApiData] = useState<any>(null);
  const [tenpoItemData, setTenpoItemData] = useState<any>(null);
  const [storePmaAllList, setStorePmaAllList] = useState<any>(null);
  const [timeout, setTimeout] = useState(false);
  const [loading, setLoading] = useState(true);
  const [errorAPI, setErrorAPI] = useState(false);
  // const chartRef = useRef<HTMLDivElement | null>(null);
  const [sortPMA, setSortPMA] = useState(false);
  const [tobaccoFlag, setTobaccoFlag] = useState(false);
  const [reservationFlag, setReservationFlag] = useState(false);
  const [specialFlag, setSpecialFlag] = useState(false);
  const [filteredList, setFilteredList] = useState<string[]>([]);
  const [sortModalOpen, setSortModalOpen] = useState(false);
  const [modalTobaccoFlag, setModalTobaccoFlag] = useState(false);
  const [modalReservationFlag, setModalReservationFlag] = useState(false);
  const [modalSpecialFlag, setModalSpecialFlag] = useState(false);
  const [modalValue, setModalValue] = useState('all');

  const modalClose = () => setErrorAPI(false);

  const search = useLocation().search;
  const usp = new URLSearchParams(search);
  const windowName = "品揃え分析（上位100単品）";

  useEffect(() => {
    let optionsW: any[] = getWeekOptions();
    let optionsM: any[] = getMonthOptions();
    setWeekDateOptions(optionsW);
    setMonthDateOptions(optionsM);
    setWeekDateValue(optionsW[0]);
    setMonthDateValue(optionsM[0]);

    const tc: string | null = usp.get("tenpocode");
    const userInfo = getUserInfo();
    if (process.env.REACT_APP_MODE !== "develop") {
      // 担当店と職種で画面表示を制御
      if (tc !== null && userAuthCheck(userInfo, tc)) {
        setTenpoCode(tc);
      } else {
        navigate("/tenpo-error");
      }
    } else {
      // ローカルでは制御しない
      if (tc !== null) {
        setTenpoCode(tc);
      }
    }
    let dt = new Date();
    const year = dt.getFullYear();
    const month = ("00" + (dt.getMonth() + 1)).slice(-2);
    setDate(year + month);
    window.setTimeout(dispMsg, timer);
    // setLoading(false); // データの初期化後にローディングを終了
  }, []);

  useEffect(() => {
    if (!timeout) {
      window.setTimeout(dispMsg, timer);
    }
  }, [timeout]);

  useEffect(() => {
    setApiData([]);
    setTenpoItemData([]);
    setStorePmaAllList([]);
    setStorePmaData([]);
    let res: any = {};
    let date: string = "";
    let newDate: string = "";
    // if (tenpoCode) {
      if (flagWM === "AssortmentAnalysisWeekly") {
        if (!weekDateValue) {
          return;
        }
        date = weekDateValue.slice(0, -1);
      } else if (flagWM === "AssortmentAnalysisMonthly") {
        date = monthDateValue.concat("/01");
      }
      newDate = date.replace(/\//g, "-");
      const params = {
        mode: flagWM,
        tenpoCode: tenpoCode,
        date: newDate,
        tobaccoFlag: tobaccoFlag,
        reservationFlag: reservationFlag,
        specialFlag: specialFlag,
        selectedRank: orderBy,
        orderBy: order,
      };
      (async () => {
        try {
          setLoading(true);
          res = await apiRequest(params);
          let tempData;
          let allData;
          if(flagWM === "AssortmentAnalysisWeekly"){
            tempData = res.data.data.AssortmentAnalysisWeeklySearchByParams[0].assortmentAnalysis; 
            allData = res.data.data.AssortmentAnalysisWeeklySearchByParams[0].tenpoItemData;
          } else if (flagWM === "AssortmentAnalysisMonthly"){
            tempData = res.data.data.AssortmentAnalysisMonthlySearchByParams[0].assortmentAnalysis;
            allData = res.data.data.AssortmentAnalysisMonthlySearchByParams[0].tenpoItemData; 
          }
          setApiData(tempData);
          setTenpoItemData(allData);
          // tenpoItemData を処理して combined_code を分割
          const processedTenpoItemData = allData.map((item: any) => {
            const [pma_cd, info_class_code] = item.combined_code.split('_');
            return {
              ...item, // 既存のプロパティを保持
              pma_cd,
              info_class_code,
            };
          });
          // 分割したデータをstorePmaAllListに格納
          const newStorePmaAllList = processedTenpoItemData.map((item:any) => ({
            pma_cd: item.pma_cd,
            pma_name: item.pma_name.replace(/\s/g, ''),
            info_cd: item.info_class_code,
            info_name: item.info_class_name.replace(/\s/g, ''),
          }));
          // storePmaAllListを更新
          setStorePmaAllList(newStorePmaAllList);
          const newStorePmaData = tempData.map((item: any) => ({
            pma_cd: item.pma_code,
            pma_name: item.pma_name.replace(/\s/g, ''),
            info_cd: item.info_class_code,
            info_name: item.info_class_name.replace(/\s/g, ''),
          }));
          newStorePmaData.sort((a: any, b: any) => {
            // pma_cd で比較
            const pmaComparison = a.pma_cd.localeCompare(b.pma_cd);
            // pma_cd が同じ場合は info_cd で比較
            if (pmaComparison === 0) {
              return a.info_cd.localeCompare(b.info_cd);
            }
            return pmaComparison;
          });
          const uniqueStorePmaData = newStorePmaData.reduce((accumulator: any[], current: any) => {
            const isDuplicate = accumulator.some(item => item.pma_cd === current.pma_cd && item.info_cd === current.info_cd);
            if (!isDuplicate) {
              return [...accumulator, current];
            }
            return accumulator;
          }, []);
          // storePmaData を更新
          setStorePmaData(uniqueStorePmaData);
        } catch (e) {
          setApiData([]);
          setTenpoItemData([]);
          setStorePmaAllList([]);
          setStorePmaData([]);
          setErrorAPI(true);
        }
        setLoading(false);
      })();
    // }
  }, [weekDateValue, monthDateValue, item, flagWM, order, orderBy]);

  useEffect(() => {
    let filteredData: any[] = [];
    if (Array.isArray(apiData)) {
        // selectedItems でフィルタリング
        filteredData = selectedItems.length > 0
        ? apiData.filter((data: any) =>
            selectedItems.includes(data.pma_code) || selectedItems.includes(`${data.pma_code}_${data.info_class_code}`)
            )
        : apiData;
        // item が "notobacco" の場合は pma_cd=61 を除外
        if (item === "notobacco") {
            filteredData = filteredData.filter((data: any) => data.pma_code !== "61");
        }
    }
    // tobaccoFlagの状態を更新
    const hasPmaCd61 = filteredData.some((data: any) => data.pma_code === "61");
    setTobaccoFlag(hasPmaCd61);

    // ソート処理
    const sortedData = sortData(filteredData, order, orderBy);
    setFilterData(sortedData);
  }, [item, selectedItems, apiData, order, orderBy]);

  const dispMsg = () => {
    setTimeout(true);
  };

  const changeWeekMonth = (valueWM: string) => {
    setFlagWM(valueWM);
  };

  const handleTermChange = (event: SelectChangeEvent) => {
    if (flagWM === "AssortmentAnalysisWeekly") {
      setWeekDateValue(event.target.value as string);
    } else if (flagWM === "AssortmentAnalysisMonthly") {
      setMonthDateValue(event.target.value as string);
    }
  };

  const handleOpenModal = () => {
    const formattedSelectedItems = storePmaData.map((item: any) => `${item.pma_cd}_${item.info_cd}`);
    setSelectedItems(formattedSelectedItems); // selectedItems を更新
    setSortModalOpen(true); // モーダルを開く
  };

  const handleCloseModal = () => {
    setSortModalOpen(false);
  };

  const handleConfirmSelection = (selected: string[], tobaccoFlag: boolean, reservationFlag: boolean, specialFlag: boolean, value: string) => {
    // 選択された PMA の情報も追加
    const newSelectedItems = [...selected];
    storePmaAllList.forEach((data: any) => {
      const key = `${data.pma_cd}_${data.info_cd}`;
      if (selected.includes(data.pma_cd) && !newSelectedItems.includes(key)) {
        newSelectedItems.push(key);
      }
    });
    setSelectedItems(newSelectedItems); // 更新された selectedItems を設定
    setFilteredList(newSelectedItems);
    setModalTobaccoFlag(tobaccoFlag);
    setModalReservationFlag(reservationFlag);
    setModalSpecialFlag(specialFlag);
    setModalValue(value);
    setSortModalOpen(false);
    setSortPMA(false);
    setTobaccoFlag(tobaccoFlag);
  };

  // ソートボタン出力
  const sortIcon = (property: string): JSX.Element => {
    if (orderBy === property) {
      return order === "asc" ? ascSort() : descSort();
    }
    return nonSort();
  };
  
  const ascSort = (): JSX.Element => {
    return (
      <IconButton css={roundIcon}>
        <ArrowDropUpIcon style={{ color: "#000000", marginBottom: "-7px" }} />
        <ArrowDropDownIcon color="disabled" style={{ marginTop: "-7px" }} />
      </IconButton>
    );
  };

  const descSort = (): JSX.Element => {
    return (
      <IconButton css={roundIcon}>
        <ArrowDropUpIcon color="disabled" style={{ marginBottom: "-7px" }} />
        <ArrowDropDownIcon style={{ color: "#000000", marginTop: "-7px" }} />
      </IconButton>
    );
  };

  const nonSort = (): JSX.Element => {
    return (
      <IconButton css={roundIcon}>
        <ArrowDropUpIcon color="disabled" style={{ marginBottom: "-7px" }} />
        <ArrowDropDownIcon color="disabled" style={{ marginTop: "-7px" }} />
      </IconButton>
    );
  };
  
  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChange = (value: string) => {
    setItem(value); // item の状態を更新
  };

  const sortData = (data: any[], order: 'asc' | 'desc', orderBy: string) => {
    return data.sort((a, b) => {
      if (a[orderBy] < b[orderBy]) {
        return order === 'asc' ? -1 : 1;
      }
      if (a[orderBy] > b[orderBy]) {
        return order === 'asc' ? 1 : -1;
      }
      return 0;
    });
  };

  const EnhancedTableHead: React.FC<{
    onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  }> = ({ onRequestSort }) => {
    const createSortHandler = (property: any) => (event: any) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow>
          <TableCell css={joinHeaderCell} style={{ left: "0px", borderLeft: "2px solid #c4c4c4" }} colSpan={3}>数量順位</TableCell>
          <TableCell css={joinHeaderCell} style={{ left: "105px" }} colSpan={3}>金額順位</TableCell>
          <TableCell css={itemNameHeaderCell} style={{ left: "210px" }} rowSpan={2}>商品名</TableCell>
          <TableCell css={infoItemNameHeaderCell} rowSpan={2}>情報分類</TableCell>
          <TableCell css={verticalHeaderCell} rowSpan={2}>商品コード</TableCell>
          <TableCell css={minVerticalHeaderCell} rowSpan={2}>商品単価</TableCell>
          <TableCell css={minVerticalHeaderCell} rowSpan={2}>基本商品</TableCell>
          <TableCell css={minBesideHeaderCell} colSpan={2}>導入率</TableCell>
          <TableCell css={besideHeaderCell} colSpan={6}>自店</TableCell>
          <TableCell css={besideHeaderCell} colSpan={6}>類似店</TableCell>
          <TableCell css={besideHeaderCell} colSpan={6}>DO</TableCell>
        </TableRow>
        <TableRow>
          <TableCell css={colHeaderCell} style={{ left: "0px", zIndex: 100, borderLeft: "2px solid #c4c4c4" }}>
            自店
            <span
              css={sortButton}
              onClick={createSortHandler("sales_quantity_rank")}
            >
              {sortIcon("sales_quantity_rank")}
            </span>
          </TableCell>
          <TableCell css={colHeaderCell} style={{ left: "35px", zIndex: 100 }}>
            類似店
            <span
              css={sortButton}
              onClick={createSortHandler("cluster_sales_quantity_rank")}
            >
              {sortIcon("cluster_sales_quantity_rank")}
            </span>
          </TableCell>
          <TableCell css={colHeaderCell} style={{ left: "70px", zIndex: 100 }}>
            地区
            <span
              css={sortButton}
              onClick={createSortHandler("do_sales_quantity_rank")}
            >
              {sortIcon("do_sales_quantity_rank")}
            </span>       
          </TableCell>
          <TableCell css={colHeaderCell} style={{ left: "105px", zIndex: 100 }}>
            自店
            <span
              css={sortButton}
              onClick={createSortHandler("sales_amount_rank")}
            >
              {sortIcon("sales_amount_rank")}
            </span>       
          </TableCell>
          <TableCell css={colHeaderCell} style={{ left: "140px", zIndex: 100 }}>
            類似店
            <span
              css={sortButton}
              onClick={createSortHandler("cluster_sales_amount_rank")}
            >
              {sortIcon("cluster_sales_amount_rank")}
            </span>       
          </TableCell>
          <TableCell css={colHeaderCell} style={{ left: "175px", zIndex: 100 }}>
            地区
            <span
              css={sortButton}
              onClick={createSortHandler("do_sales_amount_rank")}
            >
              {sortIcon("do_sales_amount_rank")}
            </span>       
          </TableCell>
          <TableCell css={colHeaderCell}>類似店</TableCell>
          <TableCell css={colHeaderCell}>DO</TableCell>
          <TableCell css={secondHeader}>販売<br />数/日</TableCell>
          <TableCell css={secondHeader}>販売金額</TableCell>
          <TableCell css={secondHeader}>欠品率<br />(デイ<br />リー)</TableCell>
          <TableCell css={secondHeader}>欠品時<br />間(非デ<br />イリー)</TableCell>
          <TableCell css={secondHeader}>廃棄率<br />(数量)</TableCell>
          <TableCell css={secondHeader}>廃棄率<br />(金額)</TableCell>
          <TableCell css={secondHeader}>販売<br />数/日</TableCell>
          <TableCell css={secondHeader}>販売金額</TableCell>
          <TableCell css={secondHeader}>欠品率<br />(デイ<br />リー)</TableCell>
          <TableCell css={secondHeader}>欠品時<br />間(非デ<br />イリー)</TableCell>
          <TableCell css={secondHeader}>廃棄率<br />(数量)</TableCell>
          <TableCell css={secondHeader}>廃棄率<br />(金額)</TableCell>
          <TableCell css={secondHeader}>販売<br />数/日</TableCell>
          <TableCell css={secondHeader}>販売金額</TableCell>
          <TableCell css={secondHeader}>欠品率<br />(デイ<br />リー)</TableCell>
          <TableCell css={secondHeader}>欠品時<br />間(非デ<br />イリー)</TableCell>
          <TableCell css={secondHeader}>廃棄率<br />(数量)</TableCell>
          <TableCell css={secondHeader}>廃棄率<br />(金額)</TableCell>
        </TableRow>
      </TableHead>
    );
  };

  return timeout ? (
    <TimeOutDisplay setTimeout={setTimeout} />
  ) : (
    <>
      <ThemeProvider theme={fontTheme()}>
        <Header
          windowName={windowName}
          tenpoCode={tenpoCode ? tenpoCode : ""}
          tenpoName={tenpoName ? tenpoName : ""}
        />
        <Grid container>
          <Grid item xs={1}></Grid>
          <Grid item xs={10}>
            <Card css={card} variant="outlined">
              <Modal open={errorAPI} onClose={modalClose}>
                <APIErrorModal />
              </Modal>
              <CardContent css={cardContent}>
                {loading ? (
                  <div>
                    <CircularProgress css={circularProgress} size={120} />
                  </div>
                ) : (
                  <>
                  <div css={containerStyle}>
                    <ToggleButtonComponent
                      options={[
                        { value: "AssortmentAnalysisWeekly", label: "週別" },
                        { value: "AssortmentAnalysisMonthly", label: "月別" },
                      ]}
                      selectedValue={flagWM}
                      orderBy={"none"}
                      onChange={changeWeekMonth}
                      loadFlag={loading}
                    />
                    <ToggleButtonComponent
                      options={[
                        { value: "AllItems", label: "煙草含む" },
                        { value: "notobacco", label: "煙草除く" },
                      ]}
                      selectedValue={item}
                      orderBy={"none"}
                      onChange={handleChange}
                      loadFlag={loading}
                    />      
                    <button onClick={handleOpenModal} css={buttonStyle}>
                      分類から選択
                    </button>
                      <div css={rightContainerStyle}>
                        {flagWM === "AssortmentAnalysisWeekly" ? (
                          <Select
                            labelId="select-label"
                            id="simple-select"
                            value={weekDateValue}
                            label="select week"
                            onChange={handleTermChange}
                            css={selectTerm}
                            disabled={loading}
                          >
                            {weekDateOptions.map((option: any) => (
                              <MenuItem
                                key={option}
                                value={option}
                                style={{ height: "20%" }}
                              >
                                {option}
                              </MenuItem>
                            ))}
                          </Select>
                        ) : (
                          <Select
                            labelId="select-label"
                            id="simple-select"
                            value={monthDateValue}
                            label="select week"
                            onChange={handleTermChange}
                            css={selectTerm}
                            disabled={loading}
                          >
                            {monthDateOptions.map((option: any) => (
                              <MenuItem
                                key={option}
                                value={option}
                                style={{ height: "20%" }}
                              >
                                {option}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      </div>
                        <Modal open={sortModalOpen} onClose={handleCloseModal}>
                          <PMASortModal
                            onConfirm={handleConfirmSelection}
                            storePmaAllList={storePmaAllList}
                            storePmaData={storePmaData}      
                            selectedItems={selectedItems}    
                            tobaccoFlag={modalTobaccoFlag}
                            reservationFlag={modalReservationFlag}
                            specialFlag={modalSpecialFlag}
                            value={modalValue}
                            setTobaccoFlag={setModalTobaccoFlag}
                            setReservationFlag={setModalReservationFlag}
                            setSpecialFlag={setModalSpecialFlag}
                            setValue={setModalValue}
                          />
                        </Modal>
                  </div>
                    <TableContainer component={Paper} css={tableContainer}>
                      <Table stickyHeader css={table}>
                        <EnhancedTableHead
                          onRequestSort={handleRequestSort}
                        />
                        <TableHead>
                        </TableHead>
                        <TableBody>
                          {filterData.map((data: any, index: number) => (
                            <TableRow key={index}>
                              <TableCell css={numberCell} style={{ left: "0px", zIndex: 50, borderLeft: "2px solid #c4c4c4" }}>
                                {data?.sales_quantity_rank}
                              </TableCell>
                              <TableCell css={numberCell} style={{ left: "35px", zIndex: 50 }}>
                                {data?.cluster_sales_quantity_rank}
                              </TableCell>
                              <TableCell css={numberCell} style={{ left: "70px", zIndex: 50 }}>
                                {data?.do_sales_quantity_rank}
                              </TableCell>
                              <TableCell css={numberCell} style={{ left: "105px", zIndex: 50 }}>
                                {data?.sales_amount_rank}
                              </TableCell>
                              <TableCell css={numberCell} style={{ left: "140px", zIndex: 50 }}>
                                {data?.cluster_sales_amount_rank}
                              </TableCell>
                              <TableCell css={numberCell} style={{ left: "175px", zIndex: 50 }}>
                                {data?.do_sales_amount_rank}
                              </TableCell>
                              <TableCell css={nameCell} style={{ left: "210px", zIndex: 50, width: "140px" }}>
                                {/* 名前はDWHのitem-pmaで確認。おそらく11文字で区切るようになっている？ */}
                                {/* {insertLineBreaks(data?.item_name, 11)} */}
                                {data?.item_name}
                              </TableCell>
                              <TableCell css={nameCell}>
                                {data?.info_class_name}
                              </TableCell>
                              <TableCell css={numberCell}>
                                {data?.item_code}
                              </TableCell>
                              <TableCell css={numberCell}>
                                {data?.item_price}
                              </TableCell>
                              <TableCell css={numberCell}>
                                {data?.basic_item}
                              </TableCell>
                              <TableCell css={numberCell}>
                                {data?.adoption_rate_similar_stores}
                              </TableCell>
                              <TableCell css={numberCell}>
                                {data?.adoption_rate_do}
                              </TableCell>
                              <TableCell css={numberCell}>
                                {data?.average_sales_quantity}
                              </TableCell>
                              <TableCell css={numberCell}>
                                {data?.average_sales_amount}
                              </TableCell>
                              <TableCell css={[numberCell, data.average_out_of_stock_rate > data.cluster_average_out_of_stock_rate && css`color: red;`]}>
                                {data?.average_out_of_stock_rate}
                              </TableCell>
                              <TableCell css={numberCell}>
                                {data?.average_out_of_stock_time}
                              </TableCell>
                              <TableCell css={numberCell}>
                                {data?.average_disposal_quantity_rate}
                              </TableCell>
                              <TableCell css={numberCell}>
                                {data?.average_disposal_amount_rate}
                              </TableCell>
                              <TableCell css={numberCell}>
                                {data?.cluster_average_sales_quantity}
                              </TableCell>
                              <TableCell css={numberCell}>
                                {data?.cluster_average_sales_amount}
                              </TableCell>
                              <TableCell css={numberCell}>
                                {data?.cluster_average_out_of_stock_rate}
                              </TableCell>
                              <TableCell css={numberCell}>
                                {data?.cluster_average_out_of_stock_time}
                              </TableCell>
                              <TableCell css={numberCell}>
                                {data?.cluster_average_disposal_quantity_rate}
                              </TableCell>
                              <TableCell css={numberCell}>
                                {data?.cluster_average_disposal_amount_rate}
                              </TableCell>
                              <TableCell css={numberCell}>
                                {data?.do_average_sales_quantity}
                              </TableCell>
                              <TableCell css={numberCell}>
                                {data?.do_average_sales_amount}
                              </TableCell>
                              <TableCell css={numberCell}>
                                {data?.do_average_out_of_stock_rate}
                              </TableCell>
                              <TableCell css={numberCell}>
                                {data?.do_average_out_of_stock_time}
                              </TableCell>
                              <TableCell css={numberCell}>
                                {data?.do_average_disposal_quantity_rate}
                              </TableCell>
                              <TableCell css={numberCell}>
                                {data?.do_average_disposal_amount_rate}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </>
                )}
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={1}></Grid>
        </Grid>
      </ThemeProvider>
    </>
  );
};
export default AssortmentAnalysis;
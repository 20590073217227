import React, { useState, useEffect, useRef } from "react";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Card,
  CardContent,
  Grid,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  Modal,
  CircularProgress,
} from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { ThemeProvider } from "@mui/material/styles";
import Header from "../common/Header";
import TimeOutDisplay from "../common/TimeOutDisplay";
import { getWeekOptions, timer, fontTheme } from "../common/utils";
import APIErrorModal from "../common/APIErrorModal";
import { userAuthCheck } from "../auth/authUser";
import { getUserInfo } from "../../data/userInfo";
import { apiRequest } from "../../apis/util";
import {
  ResponsiveContainer,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Line,
  ComposedChart,
  ReferenceLine,
} from "recharts";

const card = css`
  margin: 80px 0px 16px 16px;
  width: 100%;
  @media screen and (min-width: 1194px) {
    height: 620px;
  }
  @media screen and (min-width: 1366px) {
    height: 550px;
  }
`;
const cardContent = css`
  padding: 0;
`;
const tableContainer = css`
  padding: 0;
  border-spacing: 1;
  @media screen and (min-width: 1194px) {
    height: 590px;
  }
  @media screen and (min-width: 1280px) {
    height: 460px;
  }
  @media screen and (min-width: 1366px) {
    height: 550px;
  }
`;
const firstTable = css`
  margin: auto;
  padding: 0;
  width: 990px;
  white-space: nowrap;
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 12px;
  box-sizing: border-box;
  border: 1px solid #c4c4c4;
`;
const secondTable = css`
  margin-left: 0;
  padding: 8px;
  align-items: center;
  width: 990px;
  height: 430px;
  white-space: nowrap;
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 12px;
  box-sizing: border-box;
`;
const commonCell = css`
  margin: 0;
  padding: 4px;
  white-space: nowrap;
  border-collapse: collapse;
  border-spacing: 0;
  border: 1px solid #c4c4c4;
  font-size: 12px;
  box-sizing: border-box;
`;
const headerCell = css`
  ${commonCell}
  width: 110px;
  height: 25px;
  border-top: none;
  border-left: none;
  background-color: #e0ffee;
`;
const secondHeaderCell = css`
  ${headerCell}
  background-color: #ffffff;
`;
const circularProgress = css`
  position: absolute;
  top: 45%;
  left: 45%;
  opacity: 0.8;
  color: #ccc;
`;
const selectTerm = css`
  width: 200px;
  height: 35px;
  margin: 8px;
  padding: 0;
  text-align: left;
  background-color: #ffffff;
`;
const rightContainerStyle = css`
  display: flex;
  margin-right: 10px;
`;
const AffiliationGroup: React.FC<{}> = ({ }) => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [tenpoCode, setTenpoCode] = useState<string | null>("");
  const [tenpoName, setTenpoName] = useState<string>("");
  const [date, setDate] = useState("");
  const [weekDateValue, setWeekDateValue] = useState("");
  const [weekDateOptions, setWeekDateOptions] = useState<string[]>([]);
  const [timeout, setTimeout] = useState(false);
  const [loading, setLoading] = useState(true);
  const [errorAPI, setErrorAPI] = useState(false);
  // const chartRef = useRef<HTMLDivElement | null>(null);
  const modalClose = () => setErrorAPI(false);

  const search = useLocation().search;
  const usp = new URLSearchParams(search);
  const windowName = "所属グループ特徴";

  useEffect(() => {
    let optionsW: any[] = getWeekOptions();
    setWeekDateOptions(optionsW);
    setWeekDateValue(optionsW[0]);
    const tc: string | null = usp.get("tenpocode");
    const userInfo = getUserInfo();
    if (process.env.REACT_APP_MODE !== "develop") {
      // 担当店と職種で画面表示を制御
      if (tc !== null && userAuthCheck(userInfo, tc)) {
        setTenpoCode(tc);
      } else {
        navigate("/tenpo-error");
      }
    } else {
      // ローカルでは制御しない
      if (tc !== null) {
        setTenpoCode(tc);
      }
    }
    let dt = new Date();
    const year = dt.getFullYear();
    const month = ("00" + (dt.getMonth() + 1)).slice(-2);
    setDate(year + month);
    window.setTimeout(dispMsg, timer);
    setLoading(false); // データの初期化後にローディングを終了
  }, []);

  useEffect(() => {
    if (!timeout) {
      window.setTimeout(dispMsg, timer);
    }
  }, [timeout]);

  
  useEffect(() => {
    let res: any = {};
    let date: string = "";
    let newDate: string = "";
    if (tenpoCode) {
      if (!weekDateValue) {
        return;
      }
      date = weekDateValue.slice(0, -1);
      newDate = date.replace(/\//g, "-");
      const params = {
        tenpoCode: tenpoCode,
        date: newDate,
      };
      (async () => {
        try {
          setLoading(true);
          res = await apiRequest(params);
        } catch (e) {
          setData([]);
          setErrorAPI(true);
        }
        setLoading(false);
      })();
    }
  }, [weekDateValue]);

  // useEffect(() => {
  //   if (chartRef.current) {
  //     const chartRect = chartRef.current.getBoundingClientRect();
  //     const referenceLineX = 50; // ReferenceLineのx値
  //     const xAxis2Domain = [25, 75]; // xAxisId={2}のdomain
  //     const xAxis2Range = [chartRect.left, chartRect.right]; // xAxisId={2}のレンジ

  //     // ReferenceLineの位置を計算
  //     const referenceLinePosition = ((referenceLineX - xAxis2Domain[0]) / (xAxis2Domain[1] - xAxis2Domain[0])) * (xAxis2Range[1] - xAxis2Range[0]) + xAxis2Range[0];

  //     // Legendの位置を設定
  //     setLegendPosition({ top: chartRect.top + 10, left: referenceLinePosition });
  //   }
  // }, [chartRef]);

  const dispMsg = () => {
    setTimeout(true);
  };

  const dailyData = [
    { name: "朝食・軽食", class_rate: 20, deviation: 50 },
    { name: "タバコとついで買い", class_rate: 15, deviation: 54 },
    { name: "水分補給", class_rate: 14, deviation: 52 },
    { name: "しっかり食事", class_rate: 13, deviation: 46 },
    { name: "デザート・間食", class_rate: 12, deviation: 51 },
    { name: "コーヒー・栄養ドリンク", class_rate: 11, deviation: 42 },
    { name: "晩酌需要", class_rate: 6, deviation: 53 },
    { name: "食材・デリカ", class_rate: 5, deviation: 41 },
    { name: "緊急の日用品調達", class_rate: 4, deviation: 38 },
    { name: "日用品書い足し", class_rate: 3, deviation: 48 },
    { name: "娯楽・玩具", class_rate: 2, deviation: 57 },
  ];

  // 構成比の最大値を取得
  const maxClassRate = Math.max(...dailyData.map(d => d.class_rate));

  // 構成比の最大値を取得
  const maxDeviation = Math.max(...dailyData.map(d => d.deviation));

  // ラベル表示のため構成比の最大値＋5を格納した配列を作成
  const classRateTicks = [];
  for (let i = 0; i <= maxClassRate + 5; i += 5) {
    classRateTicks.push(i);
  }

  // ラベル表示のため構成比の最大値＋5を格納した配列を作成
  const deviationTicks = [];
  for (let i = 0; i <= 100; i += 10) {
    deviationTicks.push(i);
  }

  // Legendのラベルをカスタマイズする関数
  const legendFormatter = (value: any) => {
    if (value === "class_rate") return "構成比(%)";
    if (value === "deviation") return "偏差値";
    return value;
  };

  // Tooltipのラベルをカスタマイズする関数
  const tooltipFormatter = (value: any, name: any) => {
    if (name === "class_rate") return [value + "(%)", "構成比"];
    if (name === "deviation") return [value, "偏差値"];
    return [value, name];
  };

  const handleTermChange = (event: SelectChangeEvent) => {
    setWeekDateValue(event.target.value as string);
  };

  return timeout ? (
    <TimeOutDisplay setTimeout={setTimeout} />
  ) : (
    <>
      <ThemeProvider theme={fontTheme()}>
        <Header
          windowName={windowName}
          tenpoCode={tenpoCode ? tenpoCode : ""}
          tenpoName={tenpoName ? tenpoName : ""}
        />
        <Grid container>
          <Grid item xs={1}></Grid>
          <Grid item xs={10}>
            <Card css={card} variant="outlined">
              <Modal open={errorAPI} onClose={modalClose}>
                <APIErrorModal />
              </Modal>
              <CardContent css={cardContent}>
                {loading && (
                  <div>
                    <CircularProgress css={circularProgress} size={120} />
                  </div>
                )}
                {!loading && (
                  <TableContainer css={tableContainer}>
                    <div css={rightContainerStyle}>
                      <Select
                        labelId="select-label"
                        id="simple-select"
                        value={weekDateValue}
                        label="select week"
                        onChange={handleTermChange}
                        css={selectTerm}
                        disabled={loading}
                      >
                        {weekDateOptions.map((option: any) => (
                          <MenuItem
                            key={option}
                            value={option}
                            style={{ height: "20%" }}
                          >
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                    <Table css={firstTable}>
                      <TableRow>
                        <TableCell css={headerCell}>ZO</TableCell>
                        <TableCell css={headerCell}>DO</TableCell>
                        <TableCell css={headerCell}>店番</TableCell>
                        <TableCell css={headerCell}>クラスタNo</TableCell>
                        <TableCell css={headerCell}>特徴的な来店目的</TableCell>
                        <TableCell css={headerCell}>特徴的な時間帯</TableCell>
                        <TableCell css={headerCell}>立地</TableCell>
                        <TableCell css={headerCell}>客単価</TableCell>
                        <TableCell css={headerCell}>買上点数</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell css={secondHeaderCell}>東東京</TableCell>
                        <TableCell css={secondHeaderCell}>千代田区</TableCell>
                        <TableCell css={secondHeaderCell}>123456</TableCell>
                        <TableCell css={secondHeaderCell}>11</TableCell>
                        <TableCell css={secondHeaderCell}>しっかり食事型</TableCell>
                        <TableCell css={secondHeaderCell}>夜</TableCell>
                        <TableCell css={secondHeaderCell}>住宅型</TableCell>
                        <TableCell css={secondHeaderCell}>831円</TableCell>
                        <TableCell css={secondHeaderCell}>4.0点</TableCell>
                      </TableRow>
                    </Table>
                    <Table css={secondTable}>
                      <ResponsiveContainer width="100%" height={430} >
                        <ComposedChart layout="vertical" data={dailyData} margin={{ top: 0, right: 20, left: 20, bottom: 10 }} >
                          <XAxis xAxisId={1} type="number" domain={[0, maxClassRate + 5]} ticks={classRateTicks} label={{ value: "構成比(%)", position: "insideBottom", offset: -1 }} />
                          <XAxis xAxisId={2} type="number" ticks={deviationTicks} tick={false} orientation="top" label={"偏差値(50)"}/>
                          <YAxis type="category" dataKey="name" width={150} />
                          <Tooltip formatter={tooltipFormatter} />
                          <CartesianGrid horizontal={false} />
                          <CartesianGrid stroke="#ccc" vertical={true} verticalPoints={classRateTicks} />
                          <Bar xAxisId={1} dataKey="class_rate" stackId="a" fill="#9DD29C" />
                          <Line xAxisId={2} type="linear" dataKey="deviation" stroke="#000000" />
                          <ReferenceLine xAxisId={2} x={50} stroke="black" />
                        </ComposedChart>
                      </ResponsiveContainer>
                    </Table>
                  </TableContainer>
                )}
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={1}></Grid>
        </Grid>
      </ThemeProvider>
    </>
  );
}

export default AffiliationGroup;
import React, { useState } from "react";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
// import styled from "@emotion/styled";
import {
  IconButton,
  Drawer,
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Divider,
  Typography,
  Grid,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { useLocation } from 'react-router-dom';

const menuButton = css`
  // background-color: #d9d9d9;
  height: 50px;
  width: 50px;
  padding: 10px;
`;

const HamburgerMenu: React.FC<{ tenpoCode: string }> = ({ tenpoCode }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(0);
  const location = useLocation();

  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number
  ) => {
    setSelectedItem(index);
  };

  const menuItems = [
    { name: "個店経営状況（個別）サマリ", url: "/?mode=daily-store-summary", pathname:"/daily-store-summary" },
    // { name: "個店経営状況（サブ）", url: "/?mode=daily-store-multi", pathname:"/daily-store-multi" },
    { name: "日別管理表", url: "/?mode=daily-management", pathname:"/daily-management" },
    // { name: "月別管理表", url: "/?mode=monthly-management", pathname:"/monthly-management" },
    { name: "廃棄検証（PMA）", url: "/?mode=disposal-verification-pma", pathname:"/disposal-verification-pma" },
    // { name: "廃棄検証（情報分類）", url: "/?mode=disposal-verification-info", pathname:"/disposal-verification-info" },
    { name: "POS-PMA分析_サマリ", url: "/?mode=pos-analysis-summary", pathname:"/pos-analysis-summary" },
    { name: "POS-PMA分析_PMA", url: "/?mode=pos-analysis-pma", pathname:"/pos-analysis-pma" },
    { name: "POS-PMA分析_PMA推移", url: "/?mode=pos-analysis-transition", pathname:"/pos-analysis-transition" },
    { name: "品揃え分析（上位100単品）", url: "/?mode=assortment-analysis", pathname:"/assortment-analysis" },
    { name: "所属グループ特徴", url: "/?mode=affiliation-group", pathname:"/affiliation-group" },
  ];

  const menuList = (
    <Box sx={{ width: 300 }} role="presentation">
      <Box sx={{ p: 1 }}>
        <Grid container>
          <Grid item xs={10}>
            <Typography variant="h6">OFCダッシュボード</Typography>
          </Grid>
          <Grid item xs={2}>
            <IconButton onClick={() => setMenuOpen(false)} size={"small"}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Box>
      <Divider />
      <List>
        {menuItems.map((item, index) => (
          <ListItem key={item.name} disablePadding>
            <ListItemButton
              sx={{ pl: 3 }}
              selected={location.pathname === item.pathname}
              onClick={(event) => {
                handleListItemClick(event, index);
                window.open(item.url + "&tenpocode=" + tenpoCode, "_blank");
              }}
            >
              <ListItemText primary={item.name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
    </Box>
  );

  return (
    <>
      <IconButton onClick={() => setMenuOpen(true)} css={menuButton}>
        <MenuIcon />
      </IconButton>
      <Drawer
        anchor={"left"}
        open={menuOpen}
        onClose={() => setMenuOpen(false)}
      >
        {menuList}
      </Drawer>
    </>
  );
};

export default HamburgerMenu;
